import React from "react";
import { Route, Routes } from "react-router-dom";
import Faqspage from "../pages/Faqspage";
import LandingPage from '../pages/LandingPage';
import Aboutpage from '../pages/Aboutpage'
import MovingServicePage from "../pages/MovingServicePage";
import PackingServicePage from "../pages/PackingServicePage";
import Quote from "../pages/Quote";
import PianoServicePage from "../pages/PianoServicePage";
import JunkServicePage from "../pages/JunkServicePage";
import LoadServicePage from "../pages/LoadServicePage";
import ThankYouPage from "../pages/ThankYouPage";

function AppRoutes() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/services/1" element={<MovingServicePage/>} />
        <Route path="/services/2" element={<PackingServicePage/>} />
        <Route path="/services/3" element={<LoadServicePage/>} />
        <Route path="/services/4" element={<JunkServicePage/>} />
        <Route path="/services/5" element={<PianoServicePage/>} />
        <Route path="/about" element={<Aboutpage />} />
        <Route path="/faqs" element={<Faqspage />} />
        <Route path="/quote" element={<Quote />} />
        <Route path="/success-page" element={<ThankYouPage />} />
      </Routes>
    </div>
  );
}

export default AppRoutes;
