import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

function Faqs() {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <div className="h-auto pb-28 lg:pb-40 w-full overflow-y-hidden px-14 lg:px-20">
      <div className="flex flex-col items-center space-y-12 ">
        <h1 className=" text-sm md:text-base font-normal">FAQS</h1>
        <h1 className="  text-lg  md:text-xl lg:text-3xl  font-semibold tracking-widest text-center uppercase">
          Frequent Asked Questions
        </h1>

        <div className="mt-10 space-y-6">
          {data.map((item, i) => (
            <div
              className="border-b border-black max-w-6xl text-center pb-2 md:pb-5 cursor-pointer "
              onClick={() => toggle(i)}
            >
              <div className="flex justify-between items-center">
                <h1 className="text-xs md:text-sm lg:text-lg">{item.question}</h1>
                {selected === i ? (
                  <AiOutlineMinus className="text-base md:text-lg" />
                ) : (
                  <AiOutlinePlus className="text-base md:text-lg"/>
                )}
              </div>

              <p className={selected === i ? "content show" : "content"}>
                {item.answer}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faqs;

const data = [
  {
    question:
      "Q: How do I request a quote for my move?",
    answer:
      "A: Requesting a quote is simple! You can fill out our online quote request form on our website, providing your contact details, or you can reach out to our customer service team via phone or email on the website, and they will assist you with the process. ",
  },
  {
    question:
      "Q: How far in advance should I schedule my move?",
    answer:
      "A: It is recommended to schedule your move as early as possible to secure your preferred moving date. Ideally, you should schedule your move at least 2-4 weeks in advance, especially during peak moving seasons.",
  },

  {
    question:
      "Q: What payment methods do you accept?",
    answer: "A: We accept various payment methods, including credit cards, debit cards, and cash. Please check with our customer service team regarding specific payment options and any applicable terms and conditions.",
  },

  {
    question:
      "Q: What if my new home is not ready on the scheduled move-in day?",
    answer:
      "A: We understand that unforeseen circumstances can occur. If your new home is not ready on the scheduled move-in day, we will work with you to find a suitable solution, whether it involves storing your belongings temporarily or rescheduling the move.",
  }
  
];
