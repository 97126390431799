import React from 'react'
import MovingBanner from './MovingBanner';
import MovingServiceDesc from './MovingServiceDesc';


function MovingService() {
  return (
    <div className='h-auto w-full '>
       <MovingBanner />
       <MovingServiceDesc />

    </div>
  )
}

export default MovingService;