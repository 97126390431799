import React from 'react'
import PackingBanner from './PackingBanner'
import PackingServiceDesc from './PackingServiceDesc'

function PackingService() {
  return (
    <div>
        <PackingBanner />
        <PackingServiceDesc />
    </div>
  )
}

export default PackingService