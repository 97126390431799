import React from 'react'
import FormSection from '../components/Landingpage/FormSection'
import Form from '../components/Landingpage/Form'
import Reviews from '../components/Landingpage/Reviews'
import PackingService from '../components/Servicespage/PackingService'

function PackingServicePage() {
    return (
        <div>
             <PackingService />
             <FormSection />
             <section className='hidden lg:block -mt-40'>
               <Form />
            </section>
             <Reviews />
    
        </div>
      )
}

export default PackingServicePage