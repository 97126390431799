import React from 'react'
import PianoMoveBanner from './PianoMoveBanner'
import PianoMoveServiceDesc from './PianoMoveServiceDesc'

function PianoMoveService() {
  return (
    <div className='h-auto w-full '>
    <PianoMoveBanner />
    <PianoMoveServiceDesc />

 </div>
  )
}

export default PianoMoveService