import React from 'react'

function PianoMoveBanner() {
  return (
    <div className='bg-piano relative bg-cover bg-center p-40 md:p-60 lg:p-72 flex items-center justify-center'>
      <h1 className='text-white text-lg  md:text-xl lg:text-3xl '>Piano Moves</h1>
      <div class="absolute inset-0 bg-black opacity-40"></div>
    </div>
  )
}

export default PianoMoveBanner