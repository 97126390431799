import React from "react";
import Faqs from "../components/Faqs/Faqs";

function Faqspage() {
  return (
    <div className="flex flex-col items-center space-y-5 ">
      <Faqs />
    </div>
  );
}

export default Faqspage;



