import React from "react";
import AboutUs from "../components/About/AboutUs";
import Reasons from "../components/About/Reasons";

function Aboutpage() {
  return (
    <div>
      <AboutUs />
      <Reasons />
    </div>
  );
}

export default Aboutpage;
