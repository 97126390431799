import React from 'react'
import { Link } from 'react-router-dom';

function Hero() {
  return (
    <div className="bg-hero bg-cover bg-center h-[500px] md:h-auto w-full flex flex-col justify-end items-center md:justify-center md:items-start ">
    <div className="bg-[#4e1745] md:bg-transparent text-center md:text-left w-full py-7 md:py-20 lg:py-40 max-w-[1550px] mx-auto space-y-2 lg:space-y-8 px-6 md:px-10 text-white ">
      <h1 className="text-xl md:text-[2rem] lg:text-[2.8rem] xl:text-[3.5rem] font-bold max-w-[40rem] lg:max-w-[60rem] tracking-wider md:leading-loose lg:leading-14">
        Let us take the stress out of your move and ensure a seamless transition to your new destination.
      </h1>
      <p className="text-xs md:text-sm lg:text-lg max-w-xl lg:max-w-2xl">
        Contact us today for a free quote or to discuss your moving needs. We look forward to serving you!
      </p>
      <div>
        <Link
          to="/quote"
          className="hidden lg:inline-block px-2 md:px-8 py-2 md:py-3.5 bg-yellow-300 rounded-md text-white text-xs lg:text-base"
        >
          Request Quote
        </Link>
      </div>
    </div>
</div>


  );
}

export default Hero;
