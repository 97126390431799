import React from "react";
import { Link } from "react-router-dom";


function FormSection() {
  return (
    <div className="h-auto xl:h-screen w-full bg-formsection bg-cover">
      <div className="flex flex-col lg:flex-row items-center justify-center space-y-8 lg:space-x-10 py-16 lg:pt-52 px-6 lg:px-24 text-black  gap-7">
        <div className="col-span-2 space-y-3 text-white">
          <h1 className="text-lg md:text-xl lg:text-4xl font-extrabold max-w-2xl">
          Our pricing is competitive while ensuring the highest quality of service.
          </h1>
          <p className="text-sm lg:text-lg  max-w-2xl">
          Once we receive your quote request, our team will promptly review the details and prepare a comprehensive and transparent estimate for your move.
          </p>
        </div>

        <div className="flex justify-between items-center space-x-5 lg:space-x-10 text-white">
          <div className="flex flex-col items-center space-y-4">
             <img src="/reliability 1.svg" alt="reliability 1 svg" className="h-14 md:h-20" />
            <h2 className="text-xs  md:text-lg lg:text-xl text-center">Realiable and Trustworthy</h2>
          </div>
          <div className="flex flex-col items-center space-y-4">
             <img src="/winner 1.svg" alt="winner 1 svg" className="h-14 md:h-20"  />
            <h2 className="text-xs  md:text-lg lg:text-xl text-center">Competitive Pricing</h2>
          </div>
          <div className="flex flex-col items-center space-y-4">
           <img src="/execution 1.svg" alt="execution svg" className="h-14 md:h-20"  />
            <h2 className="text-xs  md:text-lg lg:text-xl text-center">Comprehensive Services</h2>
          </div>
        </div>
         
         <div className="lg:hidden">
              <Link to="/quote" className="px-5 md:px-8 py-2 md:py-3.5 bg-yellow-300 rounded-md text-white ">
                Request Quote
              </Link>
          
         </div>
        
      </div>
    </div>
  );
}

export default FormSection;

