import React from 'react'


function Reasons() {
  return (
    <div className='h-auto w- full my-20 '>
        <div className='flex flex-col  max-w-[1680px] mx-auto  px-6 md:px-16 lg:px-24 space-y-7 md:space-y-14 items-center '>
          <h1 className='  text-lg  md:text-xl lg:text-3xl font-semibold tracking-widest text-center'>Why Choose Us</h1>
          <h3 className=' text-base md:text-lg lg:text-2xl max-w-md text-center'>Our team of experienced movers is dedicated to providing top-notch service.</h3>
          <div className='grid grid-flow-row md:grid-cols-3 gap-6 md:gap-14 pb-10 md:mb-16'>
             <div className='space-y-2 md:space-y-6 flex flex-col items-center'>
                <img src="/reliability 1.svg" alt="reliability 1 svg" className="h-10 md:h-14 lg:h-20 bg-black rounded-xl" />
                <h2 className='text-base md:text-lg lg:text-xl font-semibold'>Reliable and Trustworthy</h2>
                <p className=' text-xs md:text-base font-light'> Our ultimate goal is your complete satisfaction. We value our customers and work hard to exceed their expectations. Our friendly and courteous staff is always ready to assist you and address any concerns you may have throughout the moving process.</p>
             </div>
             <div className='space-y-2 md:space-y-6 flex flex-col items-center'>
                <img src="/winner 1.svg" alt="winner 1 svg" className="h-10 md:h-14 lg:h-20 bg-black  rounded-xl"  />
                <h2 className=' text-base md:text-lg lg:text-xl font-semibold'>Competitive Pricing</h2>
                <p className=' text-xs md:text-base font-light'>We understand that moving can be a significant expense, and we strive to provide competitive pricing without compromising on the quality of our services. We offer transparent and upfront pricing, so you know exactly what to expect without any surprises.</p>
             </div>
             <div className='space-y-2 md:space-y-6 flex flex-col items-center'>
               <img src="/execution 1.svg" alt="execution svg" className="h-10 md:h-14 lg:h-20 bg-black  rounded-xl"  />
                <h2 className=' text-base md:text-lg lg:text-xl font-semibold'>Comprehensive Services</h2>
                <p className=' text-xs md:text-base font-light'>We offer a wide range of moving services tailored to meet your specific needs. From packing and loading to transportation and unpacking, we can handle it all. We also provide specialized services such as furniture assembly/disassembly, piano moving, and fragile item packing.</p>
             </div>
          </div>
        </div>

    </div>
  )
}

export default Reasons