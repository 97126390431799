import React from 'react'
import AppRoutes from '../AppRoutes'

function PageContent() {
  return (
    <div>
        <AppRoutes />
    </div>
  )
}

export default PageContent;