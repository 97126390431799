import React from 'react'

function JunkServiceDesc() {
  return (
    <div className="max-w-screen-2xl mx-auto grid grid-flow-row px-8 py-20 md:py-36 lg:py-44 gap-6 md:gap-8 lg:gap-40 lg:grid-cols-2 lg:px-12">
        <div className="space-y-6 order-last lg:order-first mt-6 lg:mt-0">

        <h1 className=" text-lg  md:text-xl lg:text-3xl  font-medium tracking-widest ">Junk Removal</h1>
        <p className=" text-xs md:text-sm  lg:text-lg lg:leading-8 font-light">
         Our junk removal service offers a convenient and efficient solution for decluttering and
         getting rid of unwanted items. Our professional team handles the entire process, from 
         lifting and loading to responsible disposal. We are equipped to remove various types of 
         junk, ensuring minimal disruption to your space. With a focus on environmentally friendly 
         practices, we prioritize recycling and donation whenever possible. Our prompt and efficient
          service saves you time and effort, allowing you to regain control of your space quickly. 
          Choose our junk removal service for a stress-free experience and a clutter-free environment. 
          Contact us today to schedule your service and enjoy a clean and organized space.
        </p>

        </div>

  
      <div className="relative justify-self-center w-full md:w-4/6 h-64 md:h-80">
        <div className="bg-junk2 bg-center bg-cover absolute w-full h-full z-10"></div>
        <div className="absolute -left-4 top-[8rem] md:top-[9.4rem] lg:top-[9.8rem] lg:-left-6 w-[90%] h-[60%] bg-yellow-500"></div>
      </div>
    </div>
  )
}

export default JunkServiceDesc