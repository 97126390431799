import { BrowserRouter } from "react-router-dom";
import PageContent from "./components/Pagecontent.js/PageContent";
import "./index.css";
import AppFooter from "./components/AppFooter";
import NewHeader from "./components/Header/NewHeader";


function App() {
  return (
    <div className=" relative w-full h-auto flex flex-col justify-between overflow-x-hidden snap-y snap-mandatory overflow-y-scroll scroll-smooth  ">
      <BrowserRouter>
      <section className="sticky z-50 w-full top-0 bg-white">
        <NewHeader />
      </section>
        
        <PageContent />
        <section id='footer'   > 
        <AppFooter />
        </section >
      </BrowserRouter>
    </div>
  );
}

export default App;
