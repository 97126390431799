import React from "react";
import Hero from "../components/Landingpage/Hero";
import Banner from "../components/Landingpage/Banner";
import Services from "../components/Landingpage/Services";
import FormSection from "../components/Landingpage/FormSection";
import Form from "../components/Landingpage/Form";
import Reviews from "../components/Landingpage/Reviews";

function LandingPage() {
  return (
    <div className="h-auto w-screen relative">
      <Hero />
      <Banner />
      <Services />
      <FormSection />
      <section className='hidden lg:block -mt-40'>
        <Form />
      </section>
      <Reviews />
    </div>
  );
}

export default LandingPage;
