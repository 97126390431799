import React  from 'react'

function Form() {
    
  return (
    <div className=' h-[700px] w-5/6 m-auto flex flex-col space-y-14 items-center justify-center p-6 bg-[#4e1745]' >
        <h1 className='text-center text-3xl font-bold text-white'>Request A Quote</h1>
        <form className='relative grid grid-cols-2 gap-48 w-5/6' action="https://formsubmit.co/ramadhan@movingwolves.com" method="POST"  >
            <div>
                <div className="mb-1 lg:mb-10 w-full">
                    <label htmlFor="firstName" className="block mb-3 text-xl tracking-wide font-medium text-white"> First Name</label>
                    <input type="text" id="firstname" name='FirstName' className="shadow-sm bg-transparent border-[1px] border-white text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 lg:p-3.5 "   required/>
                </div>
                <div className="mb-1 lg:mb-10 w-full">
                    <label htmlFor="phoneNumber" className="block mb-3 text-xl tracking-wide font-medium text-white">Phone Number</label>
                    <input type="text" id="phone number" name='PhoneNumber' className="shadow-sm bg-transparent border-[1px] border-white text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 lg:p-3.5 "  required/>
                </div>
                <div className="mb-1 lg:mb-10 w-full">
                    <label htmlFor="zipcode" className="block mb-3 text-xl tracking-wide font-medium text-white">Current Zipcode</label>
                    <input type="text" id="zipcode" name='Zipcode' className="shadow-sm bg-transparent border-[1px] border-white text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 lg:p-3.5"   required/>
                </div>
            </div>
            <div>
                    <div className="mb-1 lg:mb-10 w-full">
                    <label htmlFor='lastName' className="block mb-3 text-xl tracking-wide font-medium text-white">Last Name</label>
                    <input type="text" id="lastname" name='LastName' className="shadow-sm bg-transparent border-[1px] border-white text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 lg:p-3.5 " required/>
                </div>
                <div className="mb-1 lg:mb-10 w-full">
                    <label htmlFor='phoneNumber' className="block mb-3 text-xl tracking-wide font-medium text-white">Email</label>
                    <input type="email" id="email" name='Email' className="shadow-sm bg-transparent border-[1px] border-white text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 lg:p-3.5 "  required/>
                </div>
                <div className="mb-1 lg:mb-10 w-full">
                    <label htmlFor="desiredmovedate" className="block mb-3 text-xl tracking-wide font-medium text-white">Desired Moved Date</label>
                    <input type="date" id="movedate" name='DesiredMoveDate' className="shadow-sm bg-transparent border-[1px] border-white text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 lg:p-3.5" required/>
                </div>
            </div>

            <div className='absolute left-1/2 transform -translate-x-1/2 -bottom-10 '>
              <button className='px-10 py-4 bg-white rounded-md text-black hover:bg-yellow-300'>
                Submit
              </button>
            </div>
        </form>
        
    </div>
  )
}

export default Form;