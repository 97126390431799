import React from 'react'
import FormSection from '../components/Landingpage/FormSection';
import Form from '../components/Landingpage/Form';
import Reviews from '../components/Landingpage/Reviews';
import MovingService from '../components/Servicespage/MovingService';

function MovingServicePage() {
  return (
    <div>
         <MovingService />
         <FormSection />
         <section className='hidden lg:block -mt-40'>
           <Form />
        </section>
         <Reviews />

    </div>
  )
}

export default MovingServicePage;