import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import "../../index.css";
import { Link, NavLink } from 'react-router-dom';
import { Dropdown } from 'antd';

function NewHeader() {
  const [dropdownSeen, setDropdownSeen] =  useState(false);

  const handleDropdown = () => {
    setDropdownSeen(!dropdownSeen)
  };

  const handleClick = () => {
    setDropdownSeen(false);
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };


  const items = [
    {
      key: "1",
      label: (
        <Link
          to="/services/1"
        >
         Local Home Moving
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          to="/services/2"
        >
          Packing & Unpacking
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link
          to="/services/3"
        >
          Load & Unload
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link
          to="/services/4"
        >
          Junk Removal
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link
          to="/services/5"
        >
         Piano Moves
        </Link>
      ),
    }
   
  ];

  return (
    <header className='' >
        <div className="navbar">
            <div onClick={handleDropdown}  className="toggle_btn">
              { dropdownSeen ?  <FaTimes color='#efc22e' size={20} /> : <FaBars color='#efc22e' size={20} />}
             </div>
           
             <ul className='links'>
                <li><NavLink to="/"  className={({ isActive, isPending }) =>
                isActive
                  ? "underline underline-offset-8 text-yellow-300"
                  : isPending
                  ? "pending"
                  : "text-black font-medium text-base  Capitalize lg:text-lg"
              }> Home</NavLink></li>
                <li><NavLink to="/about"  className={({ isActive, isPending }) =>
                isActive
                  ? "underline underline-offset-8 text-yellow-300"
                  : isPending
                  ? "pending"
                  : "text-black font-medium text-base  Capitalize lg:text-lg"
              }>About</NavLink></li>
                <li><Dropdown
                    menu={{
                        items,
                    }}
                    placement="bottom"
                    arrow={{
                        pointAtCenter: true,
                        }} >
                <NavLink to="#" >
                    Services
                </NavLink>
                </Dropdown></li>
                <li><NavLink to="/faqs" className={({ isActive, isPending }) =>
                isActive
                  ? "underline underline-offset-8 text-yellow-300"
                  : isPending
                  ? "pending"
                  : "text-black font-medium text-base  Capitalize lg:text-lg"
              }> FAQS</NavLink></li>
                <li>
                  
                  <Link  onClick={scrollToBottom}> Contact Us</Link></li>

             </ul>

             <a href="/" class="flex items-center ">
              <img src="/logo 1.svg" alt="Logo" className='h-14 lg:h-28 md:ml-12' />
            </a>

            
              

            <div class="flex items-center space-x-2 ">
            <div className="input_div">
              
            </div>
              <Link to="/quote" className="px-2 md:px-8 py-2 md:py-3.5 bg-yellow-300 hover:bg-yellow-500 rounded-md text-white text-xs lg:text-base ">
                Request Quote
              </Link>
          </div>
          
            
        </div>

        <div className={`dropdown_menu ${dropdownSeen && "open"}`}>
                <li><NavLink to="/" onClick={handleClick}> Home</NavLink></li>
                <li><NavLink to="/about" onClick={handleClick}>About</NavLink></li>
                <li><Dropdown
                    menu={{
                        items,
                    }}
                    placement="bottom"
                    arrow={{
                        pointAtCenter: true,
                        }}
                         >
                <NavLink to="#" >
                    Services
                </NavLink>
                </Dropdown></li>
                <li><NavLink to="/faqs" onClick={handleClick}> FAQS</NavLink></li>
                <li><NavLink to="/contact" onClick={handleClick}> Contact Us</NavLink></li>

        </div>
    </header>
  )
}

export default NewHeader