import React from 'react'

function LoadingServiceDesc() {
  return (
      <div className="max-w-screen-2xl mx-auto grid grid-flow-row px-8 py-20 md:py-36 lg:py-44 gap-6 md:gap-8 lg:gap-40 lg:grid-cols-2 lg:px-12">
        <div className="space-y-6 order-last lg:order-first mt-6 lg:mt-0">

        <h1 className=" text-lg  md:text-xl lg:text-3xl  font-medium tracking-widest ">Loading & Unloading</h1>
        <p className=" text-xs md:text-sm  lg:text-lg lg:leading-8 font-light">
        Our load and unload service is designed to provide convenience and assistance during 
        your move. Our experienced movers efficiently and securely pack your belongings into 
        the designated vehicle or storage unit, ensuring proper organization and protection. 
        Upon reaching your destination, our skilled team carefully unloads your possessions, 
        placing them in designated rooms or areas according to your instructions. We handle 
        heavy lifting, saving you time and energy. With a focus on professionalism and customer 
        satisfaction, our goal is to make your move seamless and stress-free. You can trust us 
        to handle your belongings with care and provide a smooth transition to your new home.
        </p>

        </div>

  
      <div className="relative justify-self-center w-full md:w-4/6 h-64 md:h-80">
        <div className="bg-load2 bg-center bg-cover absolute w-full h-full z-10"></div>
        <div className="absolute -left-4 top-[8rem] md:top-[9.4rem] lg:top-[9.8rem] lg:-left-6 w-[90%] h-[60%] bg-yellow-500"></div>
      </div>
    </div>
  )
}

export default LoadingServiceDesc