import React from 'react'
import JunkService from '../components/Servicespage/JunkService'
import FormSection from '../components/Landingpage/FormSection'
import Form from '../components/Landingpage/Form'
import Reviews from '../components/Landingpage/Reviews'

function JunkServicePage() {
  return (
    <div>
         <JunkService />
         <FormSection />
         <section className='hidden lg:block -mt-40'>
           <Form />
        </section>
         <Reviews />

    </div>
  )
}

export default JunkServicePage