import React from 'react'
import PianoMoveService from '../components/Servicespage/PianoMoveService'
import FormSection from '../components/Landingpage/FormSection'
import Form from '../components/Landingpage/Form'
import Reviews from '../components/Landingpage/Reviews'

function PianoServicePage() {
  return (
    <div>
         <PianoMoveService/>
         <FormSection />
         <section className='hidden lg:block -mt-40'>
           <Form />
        </section>
         <Reviews />

    </div>
  )
}

export default PianoServicePage