import React from 'react'

function MovingBanner() {
  return (
        <div className='bg-moving-banner bg-cover bg-center p-40 md:p-60 lg:p-72 flex items-center justify-center'>
            <h1 className='text-white text-lg  md:text-xl lg:text-3xl '>  Local Home Moving</h1>
        </div>
 
  )
}

export default  MovingBanner;