import React from 'react'
import { ImQuotesLeft,ImQuotesRight } from "react-icons/im";


function Reviews() {
  return (
    <div className='h-auto w-full pb-16  '>
        <div className='py-16 lg:py-36 px-5 md:px-20   grid grid-flow-row lg:grid-cols-3 gap-20 max-w-[1700px] mx-auto'>

    
        <div className=' relative border border-black p-4 lg:p-10 flex flex-col items-center justify-between space-y-2 md:space-y-6'>
            <img src="https://images.pexels.com/photos/15906231/pexels-photo-15906231/free-photo-of-a-man-in-a-cap-in-black-and-white.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="/" className='h-16 w-16  lg:w-24  lg:h-24 rounded-full absolute  -top-8 -lg:top-11 left-[50%] transform translate-x-[-50%] ' />
            <div className='flex flex-col space-y-6'>
                <ImQuotesLeft color='yellow' size={25} />
                <h2 className='text-center text-xs md:text-sm  font-light'>I recently used Moving Wolves for my local home move, and I couldn't be happier with their service. The team arrived right on time, and they handled my belongings with utmost care. The packing was done efficiently, and all my items arrived at my new home in perfect condition. The movers were professional, friendly, and went above and beyond to make sure I was satisfied. I highly recommend Moving wolves for a stress-free moving experience.</h2>
                <ImQuotesRight  color='yellow' size={25}  className=' self-end ' />
            </div>
    
            <h1 className='text-sm md:text-base lg:text-lg font-semibold'>Matt Collins</h1>

        </div>
        <div className='relative border border-black  px-6 py-8 lg:p-10  flex flex-col items-center justify-between space-y-6'>
            <img src='https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1600' alt="/" className='h-16 w-16  lg:w-24  lg:h-24 rounded-full absolute object-fill  -top-8 -lg:top-11 left-[50%] transform translate-x-[-50%] ' />
            <div className='flex flex-col space-y-4'>
                <ImQuotesLeft color='yellow' size={25}  />
             <h2 className='text-center text-xs md:text-sm  font-light'>Highly recommended! Moving Wolves made my local move a breeze. Professional movers, careful handling, and everything arrived in perfect condition.</h2>
                <ImQuotesRight  color='yellow' size={25}  className=' self-end' />
            </div>
    
            <h1 className='text-sm md:text-base lg:text-lg font-semibold'>Edward Spears</h1>

        </div>
        <div className='relative border border-black px-6 py-8 lg:p-10  flex flex-col items-center justify-between space-y-6'>
            <img src='https://images.pexels.com/photos/3763188/pexels-photo-3763188.jpeg?auto=compress&cs=tinysrgb&w=1600' alt="/" className='h-16 w-16  lg:w-24  lg:h-24 rounded-full object-cover absolute  -top-8 -lg:top-11 left-[50%] transform translate-x-[-50%] ' />
            <div className='flex flex-col space-y-4'>
                <ImQuotesLeft color='yellow' size={25}  />
             <h2 className='text-center text-xs md:text-sm    font-light'>Exceptional service from start to finish! Transparent pricing, punctual movers, and excellent customer service. Moving wolves made my move stress-free.</h2>
                <ImQuotesRight  color='yellow' size={25}  className=' self-end' />
            </div>
    
            <h1 className='text-sm md:text-base lg:text-lg font-semibold'>Sarah Williams</h1>

        </div>
        </div>
    </div>
  )
}

export default Reviews