import React from 'react'

function Quote() {

  return (
    <div className='h-auto w-5/6 md:w-4/6 lg:w-3/6 mx-auto flex flex-col space-y-10 items-center justify-center p-6 px-7 shadow-lg my-10 lg:my-20 lg:bg-[#4e1745]'>
        <h1 className='text-center text-lg  md:text-xl lg:text-3xl font-bold text-black lg:text-white'>Request A Quote</h1>
        <form className='w-5/6' action="https://formsubmit.co/ramadhan@movingwolves.com" method="POST"  >
        <div className="flex flex-col space-y-4 lg:space-y-6">
            <div>
            <label htmlFor="firstName" className="block mb-1 text-xs md:text-base  lg:tracking-wide font-medium  lg:text-white">First Name</label>
            <input type="text" name='FirstName' id="firstname" className="shadow-sm bg-transparent border-[1px] border-black lg:border-white lg:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-3.5" required   />
            </div>

            <div>
            <label htmlFor="lastName" className="block mb-1 text-xs md:text-base  lg:tracking-wide font-medium lg:text-white">Last Name</label>
            <input type="text" id="lastname" name='LastName' className="shadow-sm bg-transparent border-[1px] border-black lg:border-white lg:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-3.5" required   />
            </div>

            <div>
            <label htmlFor="phoneNumber" className="block mb-1 text-xs md:text-base  lg:tracking-wide font-medium lg:text-white">Phone Number</label>
            <input type="number" id="phone number" name='PhoneNumber' className="shadow-sm bg-transparent border-[1px] border-black lg:border-white lg:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-3.5" required  />
            </div>

            <div>
            <label htmlFor="Email" className="block mb-1 text-xs md:text-base  lg:tracking-wide font-medium lg:text-white">Email</label>
            <input type="email" id="email"  name='Email' className="shadow-sm bg-transparent border-[1px] border-black lg:border-white lg:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-3.5" required   />
            </div>

            <div>
            <label htmlFor="zipcode" className="block mb-1 text-xs md:text-base  lg:tracking-wide font-medium lg:text-white">Current Zipcode</label>
            <input type="text" id="zipcode" name='Zipcode' className="shadow-sm bg-transparent border-[1px] border-black lg:border-white lg:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-3.5" required  />
            </div>
            
            <div>
            <label htmlFor="desiredMoveDate" className="block mb-1 text-xs md:text-base  lg:tracking-wide font-medium lg:text-white">Desired Move Date</label>
            <input type="date" id="movedate" name='DesiredMoveDate' className="shadow-sm bg-transparent border-[1px] border-black lg:border-white lg:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 lg:p-3.5" required />
            </div>

            <div className="flex justify-center">
                <button className='px-6 lg:px-8 py-2.5  bg-yellow-300 hover:bg-yellow-500 rounded-md text-white'>
                 Submit
                </button>
            </div>
        </div>

        
    </form>
    
  </div>
  
  )
}

export default Quote