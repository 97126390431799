import React from "react";

function MovingServiceDesc() {
  return (
    <div className="max-w-screen-2xl mx-auto grid grid-flow-row px-8 py-20 md:py-36 lg:py-44 gap-6 md:gap-8 lg:gap-40 lg:grid-cols-2 lg:px-12">
        <div className="space-y-6 order-last lg:order-first mt-6 lg:mt-0">

        <h1 className=" text-lg  md:text-xl lg:text-3xl  font-medium tracking-widest ">Local Home Moving</h1>
        <p className=" text-xs md:text-sm  lg:text-lg lg:leading-8 font-light">
        Our local home moving services are tailored to meet your specific needs, whether you're relocating to a nearby neighborhood or across town.
        Our experienced movers have extensive knowledge of local routes, ensuring efficient and timely transportation of your belongings.
        We handle every aspect of your move, from packing and loading to transportation and unloading, providing a seamless and stress-free experience.
        With our attention to detail and commitment to customer satisfaction, you can trust us to safely and securely transport your possessions to your new home.
        Our goal is to make your local move a smooth and hassle-free process, allowing you to focus on settling into your new surroundings.
        </p>

        </div>

  
      <div className="relative justify-self-center w-full md:w-4/6 h-64 md:h-80">
        <div className="bg-local bg-center bg-cover absolute w-full h-full z-10"></div>
        <div className="absolute -left-4 top-[8rem] md:top-[9.4rem] lg:top-[9.8rem] lg:-left-6 w-[90%] h-[60%] bg-yellow-500"></div>
      </div>
    </div>
    
  );
}

export default MovingServiceDesc;
