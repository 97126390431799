import React from 'react'



function Banner() {
  return (
    <div className="max-w-screen-2xl mx-auto grid grid-flow-row  lg:pt-24 px-8 gap-6 md:gap-8 lg:gap-32 lg:grid-cols-2  lg:px-12">
     <div className='flex flex-col py-16 space-y-6 lg:space-y-14 order-last lg:order-first '>
        <h1 className='text-lg lg:text-2xl font-semibold max-w-2xl'> Whether you're moving to a new home or relocating your business.</h1>
      <p className='text-sm md:text-base max-w-2xl'>We have the expertise and resources to handle all your moving requirements.Why choose us?</p>
        <div>
          <ul className='space-y-4 text-lg'>
            <li className='flex items-center space-x-3'>
              <img src="/verified 1.svg" alt="verified svg" className='h-5 lg:h-9' />
              <p className='text-xs md:text-base'>Simple and Fast, With Flexible Scheduling</p>
            </li>
            <li className='flex items-center space-x-3'>
              <img src="/verified 1.svg" alt="verified svg" className='h-5 lg:h-9' />
              <p  className='text-xs md:text-base'> We offer a wide range of services to meet all your moving needs. </p>
            </li>
            <li className='flex items-center space-x-3'>
              <img src="/verified 1.svg" alt="verified svg" className='h-5 lg:h-9' />
              <p  className='text-xs md:text-base'>We pay meticulous attention to detail throughout the entire moving process.</p>
            </li>
            <li className='flex items-center space-x-3'>
              <img src="/verified 1.svg" alt="verified svg" className='h-5 lg:h-9' />
              <p  className='text-xs md:text-base'> We offer transparent pricing with no hidden costs</p>
            </li>
            <li className='flex items-center space-x-3'>
              <img src="/verified 1.svg" alt="verified svg" className='h-5 lg:h-9' />
              <p  className='text-xs md:text-base'> Our top priority is customer satisfaction. </p>
            </li>
          </ul>
        </div>
      </div>
  
  
      <div className="relative justify-self-center w-full md:w-4/6 lg:w-full  h-64 md:h-80 lg:h-[30rem] mt-8">
        <div className="bg-car bg-center bg-cover absolute w-full h-full z-10"></div>
        <div className="absolute -left-4 top-[8rem] md:top-[9.4rem] lg:top-[13.8rem] lg:-left-6 w-[90%] h-[60%] bg-yellow-500"></div>
      </div>
    </div>

  );
}

export default Banner



