import React from 'react'

function PackingServiceDesc() {
    return (
      <div className="max-w-screen-2xl mx-auto grid grid-flow-row px-8 py-20 md:py-36 lg:py-44 gap-6 md:gap-8 lg:gap-40 lg:grid-cols-2 lg:px-12">
      <div className="space-y-6 order-last lg:order-first mt-6 lg:mt-0">

        <h1 className=" text-lg  md:text-xl lg:text-3xl  font-medium tracking-widest ">Packing & Unpacking</h1>
        <p className=" text-xs md:text-sm  lg:text-lg lg:leading-8 font-light">
        Our professional packers are skilled in efficiently and securely packing your items, ensuring their safety during transportation.
        We use high-quality packing materials and techniques to protect fragile and valuable items, giving you peace of mind throughout the move.
        Our packing services can be customized to your specific needs, whether you require assistance with the entire packing process or only certain items.
        Upon arrival at your new home, our team can also assist with unpacking, helping you save time and quickly settle into your new space.
        With our meticulous approach and attention to detail, we strive to make the packing and unpacking process as smooth and organized as possible, allowing you to enjoy a stress-free move.
        </p>

        </div>


    <div className="relative justify-self-center w-full md:w-4/6 h-64 md:h-80">
      <div className="bg-wrap bg-center bg-cover absolute w-full h-full z-10"></div>
      <div className="absolute -left-4 top-[8rem] md:top-[9.4rem] lg:top-[9.8rem] lg:-left-6 w-[90%] h-[60%] bg-yellow-500"></div>
    </div>
  </div>
     
     
      );
}

export default PackingServiceDesc