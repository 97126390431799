import React from "react";
import { Link } from "react-router-dom";

function AboutUs() {
  return (
    <div className="h-auto max-w-[1680px] mx-auto overflow-hidden flex flex-col items-center px-8 space-y-6 lg:space-y-14 py-12 lg:py-20">
    <h1 className="text-lg md:text-xl lg:text-3xl font-semibold tracking-widest text-center">
      About Us
    </h1>
    <div className="max-w-screen-[1500px] grid grid-flow-row gap-6 md:gap-8 lg:gap-40 lg:grid-cols-2  lg:px-12">
      <div className="space-y-6 ">
        <p className="text-xs md:text-base lg:text-xl leading-6 lg:leading-9 font-light">
          Welcome to Moving Wolves, your trusted partner for all your moving needs!
  
          Relocating to a new place can be both exciting and stressful. At Moving Wolves, we understand the challenges that come with moving, and we are here to make your move a smooth and hassle-free experience. Whether you're moving to a new home or relocating your business, we have the expertise and resources to handle all your moving requirements.
  
          <br />
        </p>
        <div>
          <Link to="/quote" className="px-5 md:px-8 py-2 md:py-3 bg-yellow-300 rounded-md text-white">
            Request Quote
          </Link>
        </div>
      </div>
  
      <div className="relative justify-self-center w-full md:w-4/6 h-64 md:h-80">
        <div className="bg-wrap bg-center bg-cover absolute w-full h-full z-10"></div>
        <div className="absolute -left-4 top-[8rem] md:top-[9.4rem] lg:top-[9.8rem] lg:-left-6 w-[90%] h-[60%] bg-yellow-500"></div>
      </div>
    </div>
  </div>
  
  );
}

export default AboutUs;
