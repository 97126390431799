import React from 'react'
import JunkServiceDesc from './JunkServiceDesc'
import JunkBanner from './JunkBanner'

function JunkService() {
  return (
    <div className='h-auto w-full '>
       <JunkBanner/>
       <JunkServiceDesc />

    </div>
  )
}

export default JunkService