import React from 'react'

function PianoMoveServiceDesc() {
  return (
    <div className="max-w-screen-2xl mx-auto grid grid-flow-row px-8 py-20 md:py-36 lg:py-44 gap-6 md:gap-8 lg:gap-40 lg:grid-cols-2 lg:px-12">
        <div className="space-y-6 order-last lg:order-first mt-6 lg:mt-0">

        <h1 className=" text-lg  md:text-xl lg:text-3xl  font-medium tracking-widest ">Piano Moves</h1>
        <p className=" text-xs md:text-sm  lg:text-lg lg:leading-8 font-light">
         Our piano moving service specializes in the safe and secure transportation of pianos. Our 
         experienced team handles all types of pianos, employing specialized equipment and protective
         materials to ensure their protection throughout the move. We carefully disassemble and wrap 
         the piano, transport it in specialized vehicles with secure strapping systems, and expertly 
         reassemble and tune it at the destination. With our piano moving service, you can trust that
          your valuable instrument will be handled with utmost care and precision. Contact us today for 
          a seamless and worry-free piano relocation experience.
        </p>

        </div>

  
      <div className="relative justify-self-center w-full md:w-4/6 h-64 md:h-80">
        <div className="bg-piano2 bg-center bg-cover absolute w-full h-full z-10"></div>
        <div className="absolute -left-4 top-[8rem] md:top-[9.4rem] lg:top-[9.8rem] lg:-left-6 w-[90%] h-[60%] bg-yellow-500"></div>
      </div>
    </div>
  )
}

export default PianoMoveServiceDesc