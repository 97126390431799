import React from 'react'
import LoadingBanner from './LoadingBanner'
import LoadingServiceDesc from './LoadingServiceDesc'

function LoadingService() {
  return (
    <div className='h-auto w-full '>
       <LoadingBanner />
       <LoadingServiceDesc />

    </div>
  )
}

export default LoadingService