import React from 'react'
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom';

function Services() {
  return (
    <div className='w-full h-auto py-20 overflow-y-hidden  '>
        <div className='flex flex-col justify-center space-y-16 max-w-[100rem] mx-auto px-5 md:px-16'>

        
        <h1 className='text-center text-lg  md:text-xl lg:text-3xl font-bold'>Our Services</h1>
        <div className='grid grid-flow-row lg:grid-cols-2 gap-16 lg:gap-28'>
            <div className=' flex justify-between flex-col border-2 border-slate-600 rounded-md p-4 lg:p-10 space-y-4 lg:space-y-8'>
                <img src="/maps.svg" alt="maps svg" className='h-16 w-16 md:h-32 md:w-32 rounded-full bg-slate-700 p-3' />
                <h1 className=' text-base md:text-lg lg:text-2xl font-medium tracking-wide'>Local Home Moving</h1>
                <p className=' text-xs xl:text-base lg:tracking-wider font-light leading-5 lg:leading-8 '>
                We understand the importance of careful handling and proper loading and unloading techniques to protect your belongings during the short-distance move.
                Our movers are familiar with the local area, allowing them to navigate the best routes and ensure timely delivery of your items.
                We provide personalized attention to detail, making sure that all your furniture, appliances, and personal belongings are securely packed and transported to your new home.</p>
                 <Link to='/services/1' className='font-medium'>
                  <p className='text-sm md:text-base'>Learn More</p>
                     <BsArrowRight />
                </Link>     

            </div>

            <div className=' flex justify-between flex-col border-2 border-slate-600 rounded-md p-4 lg:p-10 space-y-4 lg:space-y-8'>
                <img src="/trolley 1.svg" alt="maps svg" className='h-16 w-16 md:h-32 md:w-32 rounded-full bg-slate-700 p-3 ' />
                <h1 className=' text-base md:text-lg lg:text-2xl font-medium tracking-wide'>Packing & Unpacking</h1>
                <p className='text-xs xl:text-base lg:tracking-wider font-light leading-5 lg:leading-8'>Our packing services save you time and effort by professionally handling the packing of your belongings.
                We use high-quality packing materials to ensure the safety and protection of your items during transportation.
                Our experienced packers are skilled in efficiently and securely packing items of all sizes, from fragile china and glassware to bulky furniture.</p>
                <Link to='/services/2' className='font-medium'>
                  <p className='text-sm md:text-base'>Learn More</p>
                     <BsArrowRight />
                </Link>  

            </div>

            <div className=' flex justify-between flex-col border-2 border-slate-600 rounded-md p-4 lg:p-10 space-y-4 lg:space-y-8'>
                <img src="/loading.png" alt="loading" className='h-16 w-16 md:h-32 md:w-32 rounded-full bg-slate-600 p-3  ' />
                <h1 className=' text-base md:text-lg lg:text-2xl font-medium tracking-wide'>  Load & Unloading</h1>
                <p className='text-xs xl:text-base lg:tracking-wider font-light leading-5 lg:leading-8'>Our loading and unloading service offers 
                expert assistance during the crucial phases of your move. Our trained professionals handle your belongings with care, employing
                 efficient techniques and proper equipment to ensure their protection. We meticulously pack and secure your items during loading,
                  minimizing the risk of damage during transit. Upon arrival at your new location, our team carefully unloads and places your 
                  belongings according to your instructions.</p>
                <Link to='/services/3' className='font-medium'>
                  <p className='text-sm md:text-base'>Learn More</p>
                     <BsArrowRight />
                </Link>  

            </div>

            <div className=' flex justify-between flex-col border-2 border-slate-600 rounded-md p-4 lg:p-10 space-y-4 lg:space-y-8'>
                <img src="/trash.png" alt="junk" className='h-16 w-16 md:h-32 md:w-32 rounded-full bg-slate-600 p-3 ' />
                <h1 className=' text-base md:text-lg lg:text-2xl font-medium tracking-wide'>Junk Removal</h1>
                <p className='text-xs xl:text-base lg:tracking-wider font-light leading-5 lg:leading-8'>Our junk removal service offers a hassle-free 
                and responsible solution for getting rid of unwanted items. Our experienced team handles the entire process, from lifting and loading
                 to proper disposal. We efficiently remove various types of junk, prioritizing environmentally friendly practices by recycling or 
                 donating usable items. With our service, you can enjoy a clean and organized space without the hassle of dealing with clutter.
                  Contact us today to schedule your junk removal service and experience a convenient and stress-free solution for decluttering.</p>
                <Link to='/services/4' className='font-medium'>
                  <p className='text-sm md:text-base'>Learn More</p>
                     <BsArrowRight />
                </Link>  

            </div>

            <div className=' flex justify-between flex-col border-2 border-slate-600 rounded-md p-4 lg:p-10 space-y-4 lg:space-y-8'>
                <img src="/cargo-mover.png" alt="piano" className='h-16 w-16 md:h-32 md:w-32 rounded-full bg-slate-700 p-3 ' />
                <h1 className=' text-base md:text-lg lg:text-2xl font-medium tracking-wide'>Piano Moves</h1>
                <p className='text-xs xl:text-base lg:tracking-wider font-light leading-5 lg:leading-8'>Our piano moving service specializes in safe and
                 secure transportation of pianos. Our experienced team handles all types of pianos with meticulous care, utilizing specialized equipment
                  and techniques. We carefully disassemble, wrap, and transport the piano to its destination, where we expertly reassemble and tune it.
                   With our service, you can trust that your valuable piano will be handled with utmost care and precision. Contact us today for a worry-free
                    piano move, ensuring your instrument arrives safely at its new home.
             </p>
                <Link to='/services/5' className='font-medium'>
                  <p className='text-sm md:text-base'>Learn More</p>
                     <BsArrowRight />
                </Link>  

            </div>




        </div>
        </div>
    </div>
  )
}

export default Services